//cursor
body .cursor {
  &-default {
    cursor: default;
  }
}

//status
.error {
  color: $danger;
}

.success {
  color: $success;
}

//margins
.mt {
  &-10 {
    margin-top: 10px;
  }

  &-20 {
    margin-top: 20px;
  }
}

.mb {
  &-10 {
    margin-bottom: 10px;
  }

  &-20 {
    margin-bottom: 20px;
  }
}

//alignment
.d-flex {
  display: flex;

  &-center {
    justify-content: center;
  }

  &-end {
    justify-content: flex-end;
  }

  &-between {
    justify-content: space-between;
  }

  .item {
    &-top {
      align-items: flex-start;

    }

    &-center {
      align-items: center;
    }

    &-bottom {
      align-items: flex-end;
    }
  }
}