﻿.search {
  font-size: 16px;
  padding: 10px;
  position: relative;

  .bi-search,
  button svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .search-input button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 2.5em;
    border: none;
    background: none;
  }

  .form-control,
  label {
    padding: 0.375rem 0.75rem;
  }

  .form-control {
    padding-left: 2rem;
    height: initial;
    background-color: #efefef;

    &:focus,
    &:not(:placeholder-shown) {
      padding: 0.375rem 0.75rem 0.375rem 2rem;
    }
  }

  label {
    display: inline-flex;
    align-items: center;
  }

  .form-control ~ label {
    letter-spacing: 0ex;
    transition: letter-spacing 0.1s, opacity 0.1s;
    display: inline-flex;
    align-items: center;
  }

  .form-control:focus ~ label,
  .form-control:not(:placeholder-shown) ~ label {
    transform: initial;
    opacity: 0;
    letter-spacing: -1ex;
  }

  .search-input span {
    position: absolute;
    height: 100%;
    top: 0;
    width: 30px;
    opacity: 0;
    transition: opacity 0.1s, left 0.1s;
  }

  .form-control:focus ~ span,
  .form-control:not(:placeholder-shown) ~ span {
    opacity: 0.65;
    left: 0;
  }

  .fully-processed {
    margin-top: 15px;
    display: block;
  }
}

.date-select-wrapper,
.datepicker .d-flex {
  grid-gap: 20px;
  flex-wrap: wrap;
}

.date-select-wrapper {
  & .datepicker.date-picker-modal {
    display: flex;
    grid-gap: 40px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .btn-link {
      font-size: 20px;
    }
  }

  @include media-breakpoint-down('lg') {
    flex-direction: column;
    align-items: center;
  }
}

.datepicker .d-flex {
  @include media-breakpoint-down('lg') {
    justify-content: center;
    align-items: center;
  }

  @include media-breakpoint-down('md') {
    flex-direction: column;
  }
}

.select {
  background: #efefef;
  border: 1px solid #ced4da;
  min-height: 28px;
}

.datepicker {
  input {
    background: #efefef;
    border: 1px solid #ced4da;
  }
}

.react-datepicker {
  min-width: 290px;

  &-popper {
    z-index: 1061;
  }

  &__month-container {
    float: none;
  }

  &__navigation:hover *::before {
    border-color: $blue;
  }

  &-text {
    border-top: 1px solid #aeaeae;
    text-align: center;
    background: #f0f0f0;
    padding: 5px 10px;
    font-weight: 600;
    border-bottom-left-radius: 0.3em;
    border-bottom-right-radius: 0.3em;
    overflow: hidden;
  }
}