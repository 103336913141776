﻿.component-side-menu {
    
    .menu-toggle {
        position: absolute;
        top: 18px;
        left: 10px;
        z-index: 201;
        width: 40px;
        height: 40px;
        border: none;
        background: none;
        color: #FFF;
        border-radius: $btn-border-radius-lg;
        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }

        .bi {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    
    .brand {
        color: #fff;
        font-weight: 500;
        font-size: 1.5rem;
        padding: 13px;
        text-align: center;

        svg {
            height: 1.1em;
            width: 0.7em;
            vertical-align: -0.25em;
        }
    }
    
    .menu {
        position: fixed;
        overflow-y: auto;
        top: 0;
        left: 0;
        z-index: 200;
        @include height-100-with-iphone-fix;
        padding-bottom: 62px;
        
        display: flex;
        flex-direction: column;
        
        width: $menu-width;

        background-color: $colour-menu-background;

        box-shadow: -25px 0 12.5px 25px rgba(0, 0, 0, 0.2);

        .menu-bottom {
            background-color: $colour-menu-background-alt;
        }

        .agent {
            padding: 10px 10px;
            color: #FFFFFF;
            
            div {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        
        ul {
            flex-grow: 1;
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                padding: 10px 10px;

                a {
                    display: block;
                    border-radius: $btn-border-radius-lg;
                    padding: 7px 10px;
                    text-decoration: none;

                    color: rgba(255, 255, 255, 0.7);
                    background-color: transparent;

                    transition: background-color 0.05s, color 0.07s;

                    &:hover {
                        color: #FFFFFF;
                        background-color: rgba(255, 255, 255, 0.1);
                    }

                    &:active {
                        color: #FFFFFF;
                        background-color: rgba(255, 255, 255, 0.3);
                    }

                    &.active {
                        color: #FFFFFF;
                        background-color: rgba(255, 255, 255, 0.2);
                    }
                }
            }
        }
    }
    
    &.full {
        .menu-toggle {
            display: none;
        }
    }
    
    &.collapsed {
        .brand {
            padding-top: 60px;
            span {
                display: none;
            }
        }
        
        .menu {
            width: $menu-width-collapsed;
            li {
                text-align: center;
            }
            .label {
                display: none;
            }
        }
        
        .agent {
            display: none;
        }
        
        &.open {
            .brand {
                span {
                    display: inline;
                }
            }
            .menu {
                width: $menu-width;
                li {
                    text-align: initial;
                }
                .label {
                    display: inline;
                }
            }
            .agent {
                display: block;
            }
        }
    }
    
    &.hidden {
        .menu-toggle {
            color: $body-color;
            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
            }
        }        
        
        .menu {
            left: -100%;
            width: 90vw;
            max-width: $menu-width;
        }
        
        &.open {
            .menu-toggle {
                color: #FFFFFF;
                &:hover {
                    background-color: rgba(255, 255, 255, 0.1);
                }
            }

            .menu {
                left: 0;
            }
        }
    }

    .language-selection {
        padding: 10px;
        select {
            width: 100%;
            padding: 5px;

            background-color: $colour-menu-background-alt;
            color: #FFFFFF;
            -webkit-appearance: none;
        }
    }
}