@mixin primary-btn($bgColor, $font-size, $font-weight:500) {
    background-color: $bgColor;
    font-size: $font-size;
    font-weight: $font-weight;
}
@mixin secondary-btn($font-size) {
    background-color: #707070;    
}

.btn {
    &-default {
        padding: 5px 20px;
        min-width: 124px;
        width: max-content;
        border: 0px;
        border-radius: 30px;
        color: white;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
    }
    &-sm {
        padding: 5px 10px;
        min-width: 70px;
        width: max-content;
        border: 0px;
        border-radius: 30px;
        color: white;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
    }
    &-red {
        background-color: $danger;
    }
    
    &-green {
        background-color: $success;
    }
    
    &-login {
        font-size: 16px;
        background-color: $blue;
    }
    
    &-cancel {
        display: flex;
        margin-left: 10px;
        font-size: 14px;
        cursor: pointer;
        align-items: center;
        span {
            color: $danger;            
            margin-left: 7px;
        }
    }
}


.redirect-back {
    background-color: #707070;
    &-mobile {
        display: none;
    }
    @include media-breakpoint-down("lg") {
        &-mobile {
            display: inline-block;
            svg {
                vertical-align: baseline;
            }
        }
        &-desktop {
            display: none;
        }
    }
}

.button-form,
.download-btns {
    display: flex;
    align-items: center;    
    grid-gap: 15px;
    &.justifyCenter {
        justify-content: center;
    }
}

.mobile-settl-block {
    display: none;
    @include media-breakpoint-down("xxl") {
        display: flex;
        justify-content: center;
        grid-gap: 15px;
        margin-bottom: 1rem;
        
    }
}

.indicator {
    display: inline-block;
    width: 38px;
    height: 38px;
    background: url("#{$dir-images}/spin-dark.svg") center center no-repeat;
}

.indicator-wrapper {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    display: block !important;
}

.btn-indicator {
    position: relative;
    overflow: hidden;
    &:before {
        content: " ";
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: url("#{$dir-images}/spin.svg") center center no-repeat;
        transform: scale(2);
        opacity: 0;
        transition: transform 0.2s, opacity 0.2s;
    }

    > span {
        display: inline-block;
        transform: scale(1);
        opacity: 1;
        transition: transform 0.2s, opacity 0.2s;
    }

    &.btn-indicator-loading {
        &:before {
            transform: scale(1);
            opacity: 0.8;
        }

        > span {
            transform: scale(0.5);
            opacity: 0.4;
        }
    }
}