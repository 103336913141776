.csv-name {
    display: flex;
    align-items: center;

    .value {
        font-size: 22px;
        font-weight: 700;
    }
}


.new-cash-bundle-page {
    .parsed-body {
        .info-block {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            grid-gap: 15px;
            font-weight: 500;
            padding-bottom: 1rem;

            .order-info {
                min-width: 34%;

                &.fields {
                    &>div {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: row;
                        margin-top: 5px;

                        h4 {
                            font-size: 20px;
                        }
                    }

                    .field {
                        &-title span {
                            margin-right: 5px;
                        }

                        &-total h4 {
                            font-weight: 700;
                        }
                    }

                    hr:not([size]) {
                        height: 1px;
                        color: #000000;
                    }
                }
            }
        }

        .bundle-table {
            @include table(50%, initial, flex-start);
            padding-top: 0;
            border: 1px #bcbcbc solid;
            background-color: white;
            border-radius: 10px;
            box-sizing: border-box;
            text-align: center;
            max-height: calc(100vh - 340px);

            .cols {
                border-bottom: 1px solid #868686;
            }

            li {
                padding: 0 10px;
                border-bottom: 0px;
            }

            .col-id {
                flex-basis: 15%;
                color: #797979;
                margin-bottom: 10px;
            }

            .col-amount {
                flex-basis: 15%;
                color: #797979;
                margin-bottom: 10px;
            }

            .col-id-val {
                flex-basis: 15%;
                color: #797979;
                padding-bottom: 12px;
                padding-top: 5px;
                border-right: 1px solid #868686;
            }

            .col-amount-val {
                flex-basis: 15%;
                color: #797979;
                padding-bottom: 12px;
                padding-top: 5px;
                border-right: 1px solid #868686;
            }
        }

        @include media-breakpoint-down("md") {
            .info-block {
                display: flex;
                flex-direction: column;
                align-items: stretch;

                div {
                    text-align: end;
                }
            }

            .bundle-table {
                .cols {
                    .col-amount {
                        flex-basis: 30%;
                    }

                    .col-id {
                        flex-basis: 30%;
                    }
                }

                .col-id-val {
                    flex-basis: 30%;
                }

                .col-amount-val {
                    flex-basis: 30%;
                }
            }
        }

        @include media-breakpoint-down("sm") {
            .csv-name {
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: center;
            }

            .bundle-table {
                max-height: calc(100vh - 340px);
                min-height: auto;

                .cols {
                    .col-amount {
                        flex-basis: 40%;
                    }

                    .col-id {
                        flex-basis: 40%;
                    }
                }

                .col-id-val {
                    flex-basis: 40%;
                }

                .col-amount-val {
                    flex-basis: 40%;
                }
            }
        }
    }

    .errors {
        span {
            font-size: 20px;
            margin-left: 10px;
        }
    }

    .new-cash-bundle-first {
        display: flex;
        flex-direction: column;

        .flow-title {
            color: #979797;
        }

        .select-flow-note {
            font-size: 11px;
            color: #920000;
        }

        .css-b62m3t-container {
            width: 250px;

            .css-1s2u09g-control {
                border-bottom: 1px solid #A8A8A8;
                background-color: #f6f7f9;
                border-style: none none solid none;
                border-radius: 0%;
                color: #2D82F5;

                .css-14el2xx-placeholder {
                    color: #2D82F5;
                }

                .css-1kwwvb1-ValueContainer2 {
                    .css-qc6sy-singleValue {
                        color: #2D82F5;
                    }
                }

                .css-qc6sy-singleValue {
                    color: #2D82F5;
                }

                .css-1okebmr-indicatorSeparator {
                    display: none;
                }

                .css-tlfecz-indicatorContainer {
                    color: #2D82F5;
                }
            }
        }

        .flow-block {
            width: 250px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .next-block {
            width: 250px;
            margin-top: 10px;
            display: flex;
            justify-content: flex-end;
        }
    }

    .new-cash-bundle-second {
        margin-top: 3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
            display: flex;
            align-items: center;

            input {
                display: none;
            }

            .upload-a {
                font-size: 20px;
                margin-left: 3px;
                color: #082280;
                cursor: pointer;
                text-decoration: underline;
                margin-left: 14px;
            }
        }

        @include media-breakpoint-down("xxl") {
            .upload-a {
                font-size: 14px;
                margin-left: 1px;
            }
        }

        @include media-breakpoint-down("lg") {
            div {
                margin-left: 0px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .upload-a {
                    font-size: 14px;
                }
            }
        }

        @include media-breakpoint-down("md") {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;

            div {
                .upload-a {
                    line-height: 1;
                    font-size: 16px;
                }
            }
        }
    }

    .placed-body {
        .sec-title {
            color: #140a5a;
            font-size: 32px;
            font-weight: 400;
        }

        .pay-text {
            word-break: break-word;
            max-width: 810px;
            margin-bottom: 20px;
            font-size: 20px;
            font-weight: 500;

            span {
                font-weight: bolder;
            }
        }

        .bank-block {
            font-size: 20px;

            .total-cost-block {
                .total-cost-value {
                    font-weight: 600;
                }
            }

            .status-block {
                display: flex;
                align-items: center;
                grid-gap: 15px;

                .status-value-green {
                    margin-left: 5px;
                    display: flex;
                    align-items: center;
                    color: green;
                }

                .status-value-red {
                    grid-gap: 15px;
                    display: flex;
                    align-items: center;
                    color: #d46a6a;

                    .mark-as-paid {
                        margin-left: 30px;
                        @include primary-btn(16px, 6px);
                    }
                }
            }

            .bank-square {
                background: white;
                margin-top: 20px;
                height: 300px;
                width: 850px;
                border-radius: 8px;
                padding: 38px;
                border: 0.5px solid #989898;

                .bank-fields {
                    display: flex;
                    flex-direction: column;

                    .bank-field {
                        display: flex;
                        justify-content: space-between;
                        padding-bottom: 15px;

                        .name {
                            font-weight: 600;
                            font-size: 20px;
                        }

                        h4 {
                            font-size: 20px;
                        }
                    }

                    div[class*="val"] {
                        display: inline-flex;
                        justify-content: space-between;
                        padding-bottom: 15px;

                        h4 {
                            font-size: 20px;
                            display: inline-flex;

                            strong {
                                margin-left: 18px;
                            }
                        }

                        .copy-field {
                            font-size: 20px;
                            cursor: pointer;
                        }

                        .name {
                            font-weight: 600;
                        }

                        .copy-field {
                            .reference {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }

        .important-block {
            max-width: 700px;
            margin-left: 15px;
            margin-top: 14px;

            .imp-value {
                font-size: 20px;
                color: #777777;
                word-break: break-word;
                font-weight: 600;
            }
        }

        .close-block {
            text-align: end;
            margin-right: 460px;

            .close {
                @include primary-btn(16px, 10px);
            }
        }

        @include media-breakpoint-down("xl") {
            .important-block {
                margin-left: 5px;
            }

            .bank-block {
                .bank-square {
                    width: 700px;
                }
            }

            .close-block {
                margin-right: 110px;

                .close {
                    margin-bottom: 10px;
                }
            }
        }

        @include media-breakpoint-down("lg") {
            .bank-block {
                .bank-square {
                    width: 650px;

                    .bank-fields {
                        .bank-field {
                            .copy-field {
                                text-align: right;
                            }
                        }
                    }
                }
            }

            .close-block {
                .close {
                    margin-bottom: 10px;
                    margin-top: 5px;
                }
            }
        }

        @include media-breakpoint-down("md") {
            .bank-block {
                .bank-square {
                    width: 512px;
                    padding: 38px 15px;

                    .bank-field {
                        h4 {
                            font-size: 16px;
                        }
                    }

                    div[class*="val"] {
                        h4 {
                            font-size: 16px;
                        }
                    }
                }
            }

            .close-block {
                text-align: end;
                margin-right: 0px;

                .imp-value {
                    font-size: 16px;
                }

                .close {
                    margin-bottom: 10px;
                    padding: 7px;
                }
            }
        }

        @include media-breakpoint-down("sm") {
            .sec-title {
                justify-content: flex-start;
            }

            .bank-block {
                .bank-square {
                    width: 551px;
                    padding: 38px 5px;

                    .bank-fields {
                        .bank-field {
                            h4 {
                                font-size: 14px;
                            }

                            .name {
                                font-size: 16px;
                            }
                        }

                        div[class*="val"]>* {
                            font-size: 16px;
                        }

                        div[class*="val"] {
                            .copy-field {
                                .reference {
                                    max-width: 73px;
                                }
                            }
                        }
                    }
                }

                .status-block {
                    .status-value-red {
                        .mark-as-paid {
                            font-size: 12px;
                            padding: 5px;
                            width: 100px;
                        }
                    }
                }
            }

            .close-block {
                margin-right: 0px;
            }
        }

        @media (max-width: 576px) {
            .pay-text {
                font-size: 14px;
            }

            .bank-block {
                .bank-square {
                    height: auto;
                    width: auto;

                    .bank-fields {
                        display: flex;
                        flex-direction: column;

                        div[class*="val"] {
                            display: flex;
                            align-items: flex-start;

                            .name {
                                font-size: 16px;
                                max-width: 160px;
                            }

                            h4 {
                                font-size: 14px;
                            }
                        }

                        div[class*="val"] {
                            display: flex;
                            align-items: center;

                            .copy-field {
                                font-size: 14px;
                                display: inline-flex;
                                align-items: center;
                            }

                            h4 {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

            .close-block {
                .close {
                    margin-bottom: 10px;
                }
            }
        }
    }

    @include media-breakpoint-down("lg") {
        .page-header {
            .parsed-title {
                font-size: 34px;
            }
        }

        .errors {
            span {
                font-size: 19px;
                margin-left: 10px;
            }
        }
    }

    @include media-breakpoint-down("md") {
        .page-header {
            .parsed-title {
                font-size: 30px;
            }
        }

        .errors {
            span {
                font-size: 14px;
                margin-left: 10px;
            }
        }
    }

    @include media-breakpoint-down("sm") {
        .page-header {
            .parsed-title {
                font-size: 24px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-left: 3rem;

                .reference {
                    font-size: 18px;
                }
            }
        }

        .errors {
            margin-top: 1rem;

            .error-item {
                font-size: 14px;
                margin-left: 10px;
            }
        }
    }

    @media (max-width: 576px) {
        h1 {
            display: flex;
            justify-content: center;
        }

        .placed-body {
            margin-left: 3rem;

            .sec-title {
                font-size: 20px;
            }

            .important-block {
                margin-left: 5px;

                .imp-value {
                    font-size: 14px;
                }
            }
        }
    }
}