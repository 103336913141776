/* ===== Scrollbar ===== */
/* Firefox */
html,
body,
.list,
.modal-body {
    scrollbar-width: auto;
    scrollbar-color: #ffffff #6c757d;
}

/* Chrome, Edge, and Safari */
html,
body,
.list .modal-body {
    & ::-webkit-scrollbar {
        width: 16px;
    }
}


html,
body,
.list .modal-body {
    & ::-webkit-scrollbar-track {
        background: white;
    }
}

html,
body,
.list .modal-body {
    & ::-webkit-scrollbar-thumb {
        background-color: #6c757d;
        border-radius: 10px;
        border: 3px solid #ffffff;
    }
}