.info-field {
    .copy-field {
        cursor: pointer;
        text-decoration: underline;
        width: auto;

        .copy-icon {
            height: 24px;
            width: 24px;
            margin-left: 5px;
        }
    }
}

.sure-modal {
    margin-top: 10.3rem;
}