.component-switch {
    appearance: none;
    -webkit-appearance: none;
    border-radius: 50px;
    width: calc(2em + 4px);
    border: 2px solid $input-border-color;
    height: calc(1em + 4px);
    position: relative;

    &:after {
        position: absolute;
        top: 0.1em;
        content: " ";
        left: 0.1em;
        background-color: $red;
        width: 0.8em;
        height: 0.8em;
        transition: left 0.2s $ease-out-cubic, background-color 0.2s $ease-out-cubic;
        border-radius: 100%;
    }

    &:checked {
        &:after {
            background-color: $green;
            left: 1.1em;
        }
    }
}