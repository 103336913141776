.order-details {
    &-right {
        margin-top: 30px;
        width: max-content;
        margin-left: auto;
    }
    & > * {
        font-size: 16px;
    }
    .btn-wrapper {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}