.page-profile {
    .profile-location-modal {
        .actions {
            padding: 1rem;

            .btn {
                width: 100%;
                font-size: 1.25rem;
            }

            @include make-row();
            & > * {
                @include make-col-ready();
                @include make-col();
                //@include make-col(6);
            }
        }
    }
    
    h3 {
        color: $blue;
        font-size: 1.25rem;
    }
    
    .current-address {
        @include make-row();
        margin-bottom: 2rem;
        margin-top: 2rem;
        
        .details {
            @include make-col-ready();
            @include make-col(10);
        }
        .edit {
            text-align: end;
            @include make-col-ready();
            @include make-col(2);
        }
    }
    
    .component-map {
        overflow: hidden;
        border-radius: 1rem;
    }
}