@mixin height-100-with-iphone-fix {
    height: 100vh;
    @supports (-webkit-touch-callout: none) {
        height: -webkit-fill-available;
    }
}

@mixin min-height-100-with-iphone-fix {
    min-height: 100vh;
    @supports (-webkit-touch-callout: none) {
        min-height: -webkit-fill-available;
    }
}