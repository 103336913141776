.site-footer {
  background-color: #00425a ;
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
  padding: 15px;
  border-top: 1px solid #fff;
  font-size: 14px;
  color: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 999;
  // @include media-breakpoint-down("sm") {
  //   position: static;
  //   margin-top: 100px;
  // }
}