﻿.fixed-validation-message {
    margin-bottom: 1rem;
    p {
        text-align: center;
        color: red;
        opacity: 0;        
        margin-bottom: 0;
        transition: opacity 0.15s;

        &.enter-active, &.enter-done {
            opacity: 1;
        }
    }
}