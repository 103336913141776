.page-availability {
    max-width: 800px;
    padding-bottom: 4rem;
    .day-schedule {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        &:not(:last-child) {
            border-bottom: 1px solid;
        }
    }

    .page-panel-title {
        user-select: none;
        -webkit-user-select: none;

        h2 {
            font-size: 1.2rem;
            padding: 0.5rem 1rem;
        }
    }

    .page-panel-content {
        padding: 1rem;
    }

    .component-switch {
        vertical-align: middle;
        float: right;
    }

    .page-panel {
        background-color: #ffffff;
        box-shadow: 0px 1px 3px rgba(30, 30, 30, 0.2);

        border-radius: 10px;
    }
    .btn-save {
        @include primary-btn(16px, 8px);
    }
}
