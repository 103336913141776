﻿@font-face {
    font-family: 'Inter';
    src: url("../fonts/inter_thin.ttf") format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url("../fonts/inter_extralight.ttf") format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url("../fonts/inter_light.ttf") format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url("../fonts/inter.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url("../fonts/inter_medium.ttf") format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url("../fonts/inter_semibold.ttf") format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url("../fonts/inter_bold.ttf") format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url("../fonts/inter_extrabold.ttf") format('truetype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url("../fonts/inter_black.ttf") format('truetype');
    font-weight: 900;
    font-style: normal;
}