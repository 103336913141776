﻿//global modal styles
.modal {
  &-content {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    background-color: transparent;
    overflow: hidden;
  }

  &-header {
    align-items: flex-start;
    color: #fff;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    h4 {
      margin-right: 10px;
    }

    .btn-close {
      background-color: #fff;
      opacity: 1;
    }

    background-color: $colour-modal-header-background;
    @include border-top-radius(calc(10px - 1px));
  }

  &-title {
    font-size: 24px;
  }

  .complete-cash-out {
    .modal-body {
      min-height: 0;
    }
  }

  &-body {
    padding: 0;
    max-height: 70vh;
    overflow-y: auto;
    min-height: 232px;
    background-color: #fff;

    &-completed {
      margin-top: 1em;

      .modal-title {
        text-align: center;
      }
    }
  }

  &-footer {
    background-color: #fff;
  }

  .body-container {
    @include make-row(0);
  }

  .section-identity {
    @include make-col-ready(0);
    @include make-col(6.5);
    padding: 1rem;
    overflow-x: auto;
  }

  .payment-reference {
    margin: 1rem;

    .label {
      font-size: 22px;
      font-weight: 400;
    }

    .reference {
      text-align: center;
      font-family: "Source Code Pro", monospace;
      font-size: 1.5rem;
      margin-bottom: 0.5em;
    }
  }

  .error-text {
    color: $danger;
    text-align: center;
  }

  .receipt {
    margin-top: 20px;
  }

  .modal-phone {
    margin-top: 20px;

    p {
      font-weight: bold;
      text-align: center;
    }
  }

  .button-form {
    margin: 2rem;
  }
}

.select-phone {
  display: flex;
  justify-content: center;
  grid-gap: 15px;

  select {
    border-radius: 10rem;
    color: black;
    padding: 12px 15px;
    margin-top: 5px;
  }
}

.status-block {
  text-align: center;
  margin-bottom: 1rem;
}

.dis-flext-col {
  display: flex;
  flex-direction: column;
}

.paid-status {
  font-weight: bold;
  color: $success;

  .mg-left-5 {
    margin-left: 5px;
  }
}

.manif-info {
  display: flex;
  align-items: center
}

//info field
.info-field {
  @include make-row(0);
  margin-bottom: 0.5em;
  font-size: 16px;

  .label {
    @include make-col(6);
    padding-inline-end: 1.5rem;
    font-weight: $font-weight-semibold;
    text-align: right;
    word-wrap: normal;
  }

  .info {
    @include make-col(6);
  }

  input {
    width: auto;
  }
}

//dialog cash
.dialogue-cash {
  .section-payment {
    @include make-col-ready();
    @include make-col(5.5);
  }

  .payment-actions {
    margin: 1rem;

    .reference-number-wrapper {
      flex-direction: column;
      align-items: center;
      grid-gap: 10px;
    }

    .row {
      .col-6 {
        text-align: center;
      }
    }
  }

  .payment-info {
    padding: 1rem;
    border-top: 1px solid $border-color;
  }

  @include media-breakpoint-up("lg") {
    .section-payment {
      border-left: 1px solid $border-color;
    }
  }

  @include media-breakpoint-down("lg") {
    .body-container {
      flex-direction: column-reverse;
    }

    .section-identity {
      @include make-col(12);
    }

    .section-payment {
      border-top: 1px solid $border-color;
      @include make-col(12);
    }
  }
}

//process modal
.process-modal {
  .modal-body {
    min-height: 150px;
  }

  .result {
    padding: 1rem;
    text-align: center;
    font-size: 20px;
  }

  .amount {
    padding: 1rem;
    text-align: center;
    font-size: 2rem;
  }

  .section-identity {
    width: 100%;
  }

  .actions {
    padding: 1rem;
    text-align: center;
    @include make-row();

    &>* {
      @include make-col-ready();
      @include make-col();
    }
  }
}

//dialogue-cash
.dialogue-cash {
  .section-identity {
    display: flex;
    flex-direction: column;

    .manifest-section {
      margin-bottom: 40px;
    }
  }

  .manifest-section-goods {
    margin-top: auto;
  }
}

.paid-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
}

.txt-al-end {
  text-align: end;
}

.mg-left-5 {
  margin-left: 5px;
}

//past order
.past-order-section {
  padding: 1rem;
  width: 45.83333%;
  border-left: 1px solid #dee2e6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .past-order-row {
    display: flex;
    grid-gap: 5px;
    justify-content: flex-start;
    margin-bottom: 1rem;

    span:first-child {
      font-weight: bold;
    }
  }

  .div-done {
    text-align: right;
  }

  @include media-breakpoint-down("lg") {
    width: auto;

    .div-done {
      text-align: center;
    }
  }
}

.mg-left-5 {
  margin-left: 5px;
}

.profile-location-modal {
  .modal-body {
    padding: 20px 20px;
  }

  .button-form {
    justify-content: center;
  }
}

.amount-input-currency {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  max-width: 205px;
  margin: 0 auto;

  span {
    font-size: 20px;
    font-weight: 600;
  }

  input {
    padding: 5px 7px;
  }
}

.change-block {
  max-width: 205px;
  margin: 1rem 0 1rem auto;
  text-align: right;
}

.manifest-section {
  .info-field {
    display: block;

    .label,
    .info {
      width: auto;
      text-align: left;
    }
  }
}

.manifest input[type="checkbox"] {
  margin-left: 10px;
  vertical-align: middle;
}

.amount-text-error {
  position: relative;
  padding-bottom: 2rem;

  .error-text {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}

.checkbox-field {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3171d8;
  grid-gap: 10px;
  margin-bottom: 0.5em;

  &-curPointer {
    cursor: pointer;
  }
}

.confirm-modal {
  .modal-dialog {
    .modal-content {
      height: 200px;
      border-bottom: 1px black;
    }
  }
}