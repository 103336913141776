.time-range-slider {
    $handle-width: 15px;
    $handle-height: 30px;

    border-bottom: 2px solid $input-border-color;
    height: $handle-height;
    margin: 10px $handle-width 25px $handle-width;
    user-select: none;
    -webkit-user-select: none;
    position: relative;

    .interval {
        user-select: none;
        pointer-events: none;
        position: absolute;
        top: 100%;
        font-size: 10px;
        transform: translateX(-50%);
        padding-top: 5px;
        opacity: 0.5;
        
        &:after {
            content: " ";
            height: 5px;
            border-right: 1px solid;
            position: absolute;
            top: 0;
            left: 50%;
        }
    }

    .span {
        background-color: $input-btn-focus-color;
        top: 2px;
        bottom: 2px;
        position: absolute;
    }

    .drag-handle {
        cursor: ew-resize;
        width: $handle-width;
        height: 15px;
        background-color: #6d7882;

        width: 10px;


        &:nth-of-type(3) {
            &:before {
                border-right: 10px solid transparent;
            }
        }

        &:nth-of-type(2) {
            transform: translateX(-100%);
            &:before {
               border-left: 10px solid transparent;
            }
        }

        &:before {
            position: absolute;
            content: " ";
            border-top: 15px solid #6d7882;
            top: 15px;
            display: block;
        }

        &:after {
            position: absolute;
            content: " ";
            display: block;
            height: 45px;
            width: 30px;
            top: -7.5px;
            left: -7.5px;

            opacity: 0.5;
        }
    }

    @include media-breakpoint-down("sm") {
        .interval:nth-child(even) {
            display: none;
        }
    }
}