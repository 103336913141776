//list (tables) styles
.list {
  padding: 0;
  font-size: 16px;
  max-height: calc(100vh - 460px);
  overflow-y: auto;
  position: relative;
  min-height: 200px;

  &-header {
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    background: #fff;
  }

  li {
    display: grid;
    grid-gap: 10px;
    min-width: fit-content;
    padding: 2px 15px;
    border-bottom: 1px solid #ccc;
    word-break: break-word;

    &:not(.list-header) {
      cursor: pointer;
    }

    &:nth-child(even) {
      background-color: #f5f5f5;
    }

    &:hover:not(.list-header),
    &:hover:nth-child(even) {
      background-color: #cdcdcd;
    }

    & div:not(.col-id):not(.col-date):not(.col-createdBy):not(.col-ref) {
      text-align: center;
    }

    &.list-loading {
      text-align: center;
      padding: 1rem;
      display: block;
    }

    &.list-noresults {
      text-align: center;
      padding: 1rem;
      display: block;
    }

    &.packed {
      background-color: $success;
    }

    .btn-column {
      align-self: center;
    }

    &.new-item {
      background-color: $success;
    }
  }

  &-col4 li {
    grid-template-columns: minmax(120px, 1fr) repeat(3, minmax(150px, 1fr));

    div {
      text-align: left !important;
    }

    .col-amount {
      text-align: right;
    }
  }

  &-col5 li {
    grid-template-columns: repeat(5, minmax(135px, 1fr));
  }

  &-col6 li {
    grid-template-columns: repeat(6, minmax(135px, 1fr));
  }

  &-col7 li {
    grid-template-columns: repeat(7, minmax(135px, 1fr));
  }

  &-col8 li {
    grid-template-columns: repeat(7, minmax(135px, 1fr)) minmax(50px, 1fr);
  }

  &-col9 {
    li {
      grid-template-columns: repeat(9, minmax(135px, 1fr));
    }

    &-idFirst li {
      grid-template-columns: minmax(60px, 1fr) repeat(8, minmax(135px, 1fr));
    }
  }

  &-col15 li {
    grid-template-columns: minmax(110px, 1fr) minmax(100px, 1fr) minmax(80px, 1fr) repeat(
        4,
        minmax(120px, 1fr)
      ) minmax(150px, 1fr) minmax(130px, 1fr) repeat(2, minmax(250px, 1fr)) repeat(
        4,
        minmax(150px, 1fr)
      );
  }

  @include media-breakpoint-down('sm') {
    max-height: calc(100vh - 480px);
    min-height: 300px;

    &-col15 {
      min-height: 100px;
    }
  }
}

//settlements page
.list-settlement {
  max-height: calc(100vh - 300px);
  min-height: auto;

  @include media-breakpoint-down('xxl') {
    max-height: calc(100vh - 420px);
  }

  @include media-breakpoint-down('md') {
    max-height: calc(100vh - 460px);
  }
}

//settlements page - total
.list-settlement-info {
  display: flex;
  grid-gap: 15px;
  justify-content: flex-end;
  padding: 0 25px 10px;
}

//list (table) statuses
.col-status {
  &-red {
    color: $danger;
  }

  &-green {
    color: $success;
  }
}

//delivery agent - completed
.list-past.list-col9 li {
  grid-template-columns: repeat(9, minmax(135px, 1fr));
}

.list-col10 li {
  grid-template-columns: repeat(10, minmax(135px, 1fr));
}
.list-col13 li {
  grid-template-columns: repeat(13, minmax(135px, 1fr));
}
.list-col14 li {
  grid-template-columns: repeat(14, minmax(135px, 1fr));
}
.list-col7 li {
  grid-template-columns: repeat(7, minmax(135px, 1fr));
}
.main-list-lazy {
  max-height: calc(100vh - 285px);

  @include media-breakpoint-down('sm') {
    max-height: calc(100vh - 300px);
  }
}

//cash rail list
.cashrail-list,
.list-kwlCashouts {
  max-height: calc(100vh - 285px);

  @include media-breakpoint-down('sm') {
    max-height: calc(100vh - 300px);
  }
}

//cashrail details list
.cashrail-details-list {
  max-height: calc(100vh - 430px);

  .col-id {
    text-align: center;
  }

  @include media-breakpoint-down('sm') {
    max-height: calc(100vh - 340px);
  }
}

//kwl kashouts

.list.list-col7.kwl-booked-multi-cashout {
  max-height: calc(100vh - 350px);
}

.list.list-col5.kwl-cashout-without-bundle {
  max-height: calc(100vh - 370px);
}

.list.list-col7.kwl-booked-multi-cashout li {
  grid-template-columns: 30px repeat(6, minmax(135px, 1fr));
}

.list.list-col7.cash-out-processing-list li {
  grid-template-columns: repeat(6, minmax(135px, 1fr)) 60px;
}

.list.list-col-2 li{
    grid-template-columns: 3fr 1.5fr;
}

//page cashrail - bundle list
.list-bundle {
  max-height: calc(100vh - 260px);

  div {
    text-align: left !important;
  }

  @include media-breakpoint-down('md') {
    max-height: calc(100vh - 400px);
  }
}

// bundle details
.list-bundle-details {
  max-height: calc(100vh - 430px);

  @include media-breakpoint-down('sm') {
    max-height: calc(100vh - 465px);
  }
}

//merchant list
.merchant-list li {
  grid-template-columns: minmax(135px, 1fr) repeat(5, minmax(100px, 1fr)) repeat(
      2,
      minmax(135px, 1fr)
    ) minmax(100px, 1fr);
}

.price-danger {
  content: '';
  display: inline-block;
  margin-left: 10px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: #fa0303;
}
