@import '../../node_modules/mapbox-gl/dist/mapbox-gl';

.component-map {
    height: 50vh;
    position: relative;

    .mapboxgl-map {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
    }
}