.custom-nav-pills.nav-pills {
    .nav-item {
        &:first-child {
            text-align: left;
        }
        &:last-child {
            text-align: right;
        }
    }
    
    button {
        text-align: inherit;
        border-radius: 0;
        border-bottom: 3px solid transparent;
        color: $body-color;
        font-weight: bold;

        &.active {
            color: $primary;
            background: none;
            border-bottom-color: $primary;
        }
    }
}