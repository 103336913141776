@mixin table($flex-b, $cursor, $just-content) {
    padding: 0;
    padding-top: 0.3em;
    font-size: 16px;

    li > div {
        flex-basis: $flex-b;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    li {
        cursor: $cursor;
        display: flex;
        justify-content: $just-content;
        padding: 2px 10px;
        border-bottom: 1px solid #868686;
        align-items: center;

        &:nth-child(even) {
            background-color: #f4f4f4;
        }
        &.list-loading {
            text-align: center;
            padding: 1rem;
            display: block;
        }
        &.list-noresults {
            text-align: center;
            padding: 1rem;
            display: block;
        }
    }
}

@mixin table-wrapper {
    overflow-x: auto;
    border: 1px solid #bcbcbc;
    border-radius: 10px;
    position: relative;
    background: #ffffff;
    padding-top: 10px;
    .search-block {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-content: center;
        margin-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        position: sticky;
        left: 0;
        div {
            width: 404px;
        }
    }
}
