.page-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    background-color: white;
    h1 { 
        display: flex;
        justify-content: space-between;
        align-items: center;
        font: $font-weight-semibold $h1-fz $font-family-main;
        margin-bottom: 0;
        color: $text-blue;
    }
    &-title {
        font: $font-weight-semibold $h1-fz $font-family-main;
    }    
    @include media-breakpoint-down("xxl") {
        .download-btns {
            display: none;
        }
    }
    @include media-breakpoint-down("lg") {
        h1,
        &-title {
            font-size: 30px;
        }
    }
    @include media-breakpoint-down("sm") {
        padding-left: 3rem;
        h1 {
            text-align: center;
            margin-top: 0;
        }
    }
}

.order-status-title  {
    font: $font-weight-semibold 22px $font-family-main;
    color: $text-blue;
}
.status-ready button {
    margin-left: 10px;
}