.page-login {
    .login-panel {
        .content {
            padding: 40px;
            form {
                text-align: center;
                div {
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
                .email-input {
                    margin-top: 40px;
                    margin-bottom: 40px;
                }
                .text-center {
                    margin-bottom: 1.5rem;
                    .text-danger {
                        margin-top: 5px;
                    }
                }
                .password-error {
                    list-style-type: none;
                    color: red;
                    padding-left: 1rem;
                }
            }

            .recovery-done {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                font-size: 16px;
                margin: 1rem 0px 1rem 0px;
                .link {
                    color: #01314e;
                    cursor: pointer;
                    text-decoration: underline;
                }
            }

            .expired {
                text-align: center;
                color: red;
            }

            .text-center {
                text-align: center;
            }

            .check-email {
                text-align: center;
                margin-top: 40px;
                margin-bottom: 40px;
                font-size: 20px;
            }
        }
    }
}
