﻿.page-dashboard {
  display: flex;
  flex-direction: column;

  padding-top: 4rem;
  padding-bottom: 5rem;

  max-width: 265px;
  margin-left: auto;
  margin-right: auto;

  .btn {
    margin-bottom: 2rem;
    text-align: left;
  }
}

.dashboard-page-panel {
  background-color: #ffffff;
  box-shadow: 0px 1px 3px rgba(30, 30, 30, 0.2);

  border-radius: 10px;

  margin-bottom: 3rem;

  .proceed-button {
    padding: 10px 10px 10px 30px;
  }

  .page-panel-title {
    h2 {
      font-size: 1.2rem;
      padding: 1rem 1.5rem;
    }
  }

  > .component-data-table {
    td:first-child,
    th:first-child {
      padding-left: 1.5rem;
    }
    td:last-child,
    th:last-child {
      padding-right: 1.5rem;
    }
  }

  > .component-data-table-footer {
    padding: 0 1.5rem;
  }
  .remove-button {
    width: 30px;
    height: 30px;
    background: transparent;
    border: none;
    span {
      display: block;
      width: 20px;
      height: 5px;
      background-color: red;
      border-radius: 4px;
    }
  }
}

.page-panel-title {
  border-bottom: 1px solid #868686;
  padding-top: 10px;
  .nav .nav-item {
    font-size: 16px;
  }
  @include media-breakpoint-down('xl') {
    .nav .nav-item .nav-link {
      text-align: center;
    }
  }
}
