$dir-images: "../images";

@import "../../node_modules/react-datepicker/src/stylesheets/datepicker.scss";
@import "fonts/sourcecodepro";
@import "fonts/inter";
@import "fonts/amplesoftpro";

@import "mixins/iphone-vh-fix";
@import "mixins/table";
@import "variables";
@import "helpers";

@import "../../node_modules/bootstrap/scss/bootstrap";

$font-size-root: 14px;
$h1-fz: 38px;

$font-weight-medium: 500;
$font-weight-semibold: 600;

$font-family-main: "AmpleSoftPro";
$font-family-secondary: "Inter";

html {
  min-height: 100%;
  min-width: 320px;
}

body {
  @include min-height-100-with-iphone-fix;
  min-width: 320px;
  background: #f6f7f9;
  font-family: $font-family-main, "Inter", sans-serif;
}

.cur-default {
  cursor: default;
}

#react-root {
  display: block;
  height: 100vh;
  @include min-height-100-with-iphone-fix;
}

.content-container {
  position: relative;
  height: 100%;

  &.side-menu-visible-full {
    margin-left: $menu-width;
  }

  &.side-menu-visible-collapsed {
    margin-left: $menu-width-collapsed;
  }

  @include media-breakpoint-down("sm") {
    &.side-menu-visible-hidden>* {
      padding-bottom: 70px;
    }
  }
}

.table> :not(:last-child)> :last-child>* {
  border-bottom-color: $table-border-color;
}


.new-item {
  background-color: #78de93;
}

.align-items-center {
  align-items: center;
}

.bi {
  display: inline-block;
  vertical-align: middle;
}

.loading {
  display: flex;
  justify-content: center;

  .spin {
    height: 50px;
    width: 50px;
    margin-top: 50px;
  }

  .not-found {
    margin-top: 75px;
    font-size: 26px;
    font-weight: 500;
  }
}

.badge {
  margin-left: 10px;
}

.pay-status-content {
  font-size: 20px;
  margin-left: 10px;
  padding: 10px 10px 10px 0px;
  color: $text-blue;
}

.mg-bot-5 {
  margin-bottom: 5px;
}

.copy-icon {
  vertical-align: bottom;
}

@import "components/buttons";
@import "components/scrollbar";
@import "components/page-header";
@import "components/reference-number-input";
@import "components/digital-number-input";
@import "components/nav-pills";
@import "components/list";
@import "components/search-input";
@import "components/modal";
@import "components/compact-field";
@import "components/side-menu";
@import "components/map";
@import "components/fixed-validation-message";
@import "components/time-range-slider";
@import "components/switch";
@import "components/order-details";
@import "components/footer";

@import "pages/login";
@import "pages/dashboard";
@import "pages/profile";
@import "pages/forgotPage";
@import "pages/recoveryPage";
@import "pages/bundle-cashrail-page";
@import "pages/newCashBundle";
@import "pages/availability";
@import "pages/kwl-cashout.scss";
@import "pages/bundle-cus-details.scss";
@import "pages/settlementDetailsPage.scss";