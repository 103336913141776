.custom-checkbox {
  cursor: pointer;
}

.page-cashout {
  .dialogue-cash-out {
    .list-header {
      font-weight: bold;
    }
    .modal-body .body-container .section-identity li .recipient-name-column {
      text-align: left;
    }
    .section-payment {
      .question-text {
        margin: 1rem;
        font-weight: bold;
      }
    }
  }
  .form-section {
    position: relative;
    min-height: 20px;
    .btn.btn-link {
      position: absolute;
      top: -20px;
      right: -10px;
    }
  }
}
