.page-login {
    .login-panel {
        .contents {
            form {
                .email-input {
                    margin-top: 40px;
                    margin-bottom: 40px;
                }
                .text-center {
                    margin-bottom: 1.5rem;
                    .text-danger{
                        margin-top: 5px;
                    }
                }
            }
            .check-email {
                text-align: center;
                margin-top: 40px;
                margin-bottom: 40px;
                font-size: 20px;
            }
        }
    }
}
