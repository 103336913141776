﻿.compact-field {
    .inner {
        position: relative;
        margin-top: 6px;

        &:after {
            content: " ";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 20;
            
            border-radius: $input-border-radius;
            transition: border 0.1s;
            border: 1px solid rgba(0, 0, 0, 0.5);
            pointer-events: none;
        }
    }

    label {
        position: absolute;
        z-index: 30;

        transition: transform 0.1s, top 0.1s, background-color 0.1s, color 0.1s;
        transform-origin: top left;
        transform: scale(1) translateY(0%);

        pointer-events: none;
        user-select: none;

        color: rgba(0, 0, 0, 0.5);
        font-size: 14px;
        background-color: transparent;
        padding: 3px 6px;
        border-radius: 3px;

        top: 10px;
        left: 9px;
    }

    input {
        border: none;
        outline: none;
        border-radius: $input-border-radius;
        color: black;
        padding: 12px 15px;
        width: 100%;
        box-shadow: 0 0 0px 1000px white inset;
    }

    &.focus {
        .inner:after {
            border: 2px solid $colour-black-blue;
        }
    }

    &.active {
        label {
            margin-left: 10px;
            transform: translateY(-100%);
            top: 13px;
            pointer-events: initial;
            user-select: initial;
            color: initial;
            background-color: $content-background-white;
        }
    }

    &.error {
        input {
            outline-color: $danger;
        }
        label {
            color: $danger;
        }
    }
}
