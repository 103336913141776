﻿.page-login {
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include min-height-100-with-iphone-fix;

    background: $colour-black-blue linear-gradient(to bottom right, $colour-black-blue 0%, #00485e 100%);

    perspective: 1000px;

    .contents {
        padding: 40px;

        .forgot-language {
            display: flex;
            margin-top: 2rem;
            justify-content: space-between;
            align-items: center;
            .forgot-field {
                text-align: right;
                padding-top: 3px;
                text-decoration: underline;
                color: $colour-black-blue;
                cursor: pointer;
            }
        }
    }

    .current-email,
    .switch-account {
        text-align: center;
        display: block;
    }

    canvas {
        //QR code
        image-rendering: pixelated;
        width: 150px;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    .twofactorkey {
        text-transform: uppercase;
        text-align: center;
        font-size: 0.875em;
        font-family: "Source Code Pro";
        border: 1px solid #bababa;
        background-color: #f7f7f7;
        display: inline-block;
        border-radius: 0.25em;
        padding: 0.25em 0.5em;
    }

    h1 {
        margin-top: 0.5em;
        margin-bottom: 1em;
        text-align: center;
        font-weight: 600;
        font-size: 28px;

        svg {
            height: 1.1em;
            width: 0.7em;
            vertical-align: bottom;
        }
    }

    .panel-container {
        margin-bottom: 40px;
        margin-top: 40px;
    }

    .login-fields {
        padding-top: 5px;
        padding-bottom: 5px;
        > * {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .login-panel {
        max-width: 400px;
        width: 100%;
        background: $content-background-white;

        margin: 0 auto 0 auto;

        border-radius: 10px;
        box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    }

    .language-selection {
        max-width: 150px;
    }
}
