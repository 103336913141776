.verify-form {
    padding-bottom: 2rem;
    padding-top: 1rem;
    position: relative;

    p {
        text-align: center;
        color: red;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.15s;
        position: absolute;
        bottom: 5px;
        width: 100%;
        margin-bottom: 0;

        &.enter-active,
        &.enter-done {
            opacity: 1;
            visibility: visible;
        }
    }

    button {
        padding: 10px;
    }

    @include media-breakpoint-down("sm") {
        padding-top: 0;
        padding-bottom: 10px;
    }
}

.digital-number {
    &-wrapper {
        text-align: center;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        grid-gap: 20px;
    }

    &-input {
        position: relative;
        max-width: 240px;
        width: 100%;

        input,
        span {
            font-family: 'Source Code Pro', monospace;
            font-size: 25px;
        }

        input {
            padding-left: calc(0.75rem + 0.375em);
            padding-right: calc(0.75rem + 0.375em);
        }

        span {
            top: calc(1px + 0.375rem);
            left: 0;
            width: 100%;
            color: rgba(0, 0, 0, 0.5);
            position: absolute;
            user-select: none;
            pointer-events: none;
        }
    }

    @include media-breakpoint-down("sm") {
        &-wrapper {
            flex-direction: column;
            align-items: center;
        }
    }
}