$blue: #1870dd;
$text-blue: #140a5a;

$danger: #d20019;
$success: #28e5aa;

$table-striped-bg: #f5f5f5;
$table-border-color: #efefef;

$btn-border-radius: 10rem;
$btn-border-radius-lg: 10rem;
$input-border-radius: 10rem;
$input-border-radius-lg: 10rem;

$colour-light-blue: #66cbfb;
$colour-black-blue: #02193d;
$colour-menu-background: #1e2435;
$colour-menu-background-alt: #2e3445;
$content-background-white: #ffffff;
$content-foreground-white: #ffffff;

$colour-modal-header-background: $colour-menu-background;

$menu-width: 250px;
$menu-width-collapsed: 80px;

$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);